.services {
  padding: 0 0 0 0;
  overflow: hidden;
  position: relative;

  &__title-sticky {
    height: 166px;
  }

  .inner-wrapper-sticky {
    z-index: 2;
    background-color: #ffffff;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    color: #302D2D;
    padding: 80px 0 0 0;
    height: 166px;

    @include sfx-media-down(sm) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__list {
    margin: 0 -15px 0 -15px;
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    padding: 0 15px;
    margin: 0 0 30px 0;
    @include sfx-flex-col(6);

    @include sfx-media-down(sm) {
      @include sfx-flex-col(12);
      margin: -1px 0 0 0;
    }

    &:nth-child(1), &:nth-child(3), &:nth-child(5), &:nth-child(7) {
      .services__item-inner {
        margin: 0 0 0 auto;
      }
    }

    &:nth-child(3) {
      margin-bottom: 0;

      @include sfx-media-down(sm) {
        margin-bottom: 30px;
      }
    }

    &:nth-child(4) {
      margin-bottom: 0;
    }
  }

  &__item {
    border: 1px solid #C4C4C4;
    background: url(images/services-bg.png) no-repeat center;
    background-size: cover;
    position: relative;
    padding: 30px 30px 50px 30px;
    height: 100%;

    @include sfx-media-down(sm) {
      border-left: 0;
      border-right: 0;
      background-image: url(images/services-bg-mobile.png);
    }

    &-inner {
      position: relative;
      padding: 0 0 90px 0;
      max-width: 510px;
      height: 100%;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 150%;
      /* or 39px */
      color: #302D2D;

      @include sfx-media-down(sm) {
        font-size: 23px;
        line-height: 150%;
      }
    }

    &-btn {
      width: 330px;
      height: 66px;
      background: #FFFFFF;
      border: 2px solid #656464;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: $font-jura;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #656464;
      transition: color .5s ease, background-color .5s ease, border .5s ease;
      position: absolute;
      bottom: 0px;
      left: 0px;

      @include sfx-media-down(sm) {
        font-size: 17px;
        line-height: 20px;
        height: 48px;
        width: 215px;
        left: auto;
        right: 0;
      }

      &:hover {
        background: #5BB5AC;
        border: 2px solid #5BB5AC;
        color: #ffffff;
        transition: color .5s ease, background-color .5s ease, border .5s ease;
      }
    }
  }



}
