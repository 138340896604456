.index {
  overflow: hidden;
}

@import "index/top-block";
@import "index/services";
@import "index/projects";
@import "index/technology";
@import "index/command";
@import "index/form";
@import "index/contacts";
@import "index/projects-detail";
