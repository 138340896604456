.top-block {
  background: url(images/top-block.png) no-repeat center;
  background-size: cover;
  height: 709px;
  margin: -73px 0 0 0;
  padding: 73px 0 0 0;
  overflow: hidden;

  &__inner {
    height: calc(709px - 73px);
    display: flex;
    align-items: center;

    @include sfx-media-down(sm) {
      margin: 0 -15px;
    }
  }

  &__content {
    max-width: 890px;
    width: 100%;
    padding: 47px 0;
    background: rgba(91, 181, 172, 0.8);
    position: relative;

    @include sfx-media-down(sm) {
      background: rgba(91, 181, 172, 0.8);
      padding: 60px 15px;
    }

    &:after {
      content: '';
      top: 0;
      right: 100%;
      display: block;
      background: rgba(91, 181, 172, 0.8);
      position: absolute;
      height: 100%;
      width: 5000px;

      @include sfx-media-down(sm) {
        display: none;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    color: #FFFFFF;
    padding: 0 0 20px 0;

    @include sfx-media-down(sm) {
      font-size: 33px;
      line-height: 40px;
    }

    @include sfx-media-down(410px) {
      .hl:nth-child(9) {
        display: block !important;
      }
    }
  }

  &__text {
    font-family: $font-jura;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 130%;
    /* or 55px */
    color: #FFFFFF;
    opacity: 0.8;
    padding: 0 0 30px 0;

    @include sfx-media-down(sm) {
      font-size: 23px;
      line-height: 130%;
      /* or 30px */
    }
  }

  &__btn {
    width: 330px;
    height: 66px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $font-jura;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #656464;
    transition: color .5s ease, background-color .5s ease, border .5s ease;

    @include sfx-media-down(sm) {
      width: 201px;
      height: 50px;
      font-size: 15px;
    }

    &:hover {
      background: #5BB5AC;
      border: 2px solid #067065;
      color: #ffffff;
      transition: color .5s ease, background-color .5s ease, border .5s ease;
    }
  }

}