.form {
  padding: 10px 0 40px 0;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    /* identical to box height */
    color: #302D2D;
    padding: 0 0 40px 0;

    @include sfx-media-down(sm) {
      font-size: 28px;
      line-height: 150%;
    }
  }

  &__block {
    max-width: 570px;
  }

  &__field {
    padding: 0 0 30px 0;
  }

  &__label {
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */
    color: #656464;
    padding: 0 0 10px 0;

    @include sfx-media-down(sm) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__tooltip {
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */
    color: #656464;
    position: absolute;
    left: 100%;
    top: 0;
    padding: 0 0 0 30px;
    width: 570px;
    opacity: 0;
    transition: opacity 0.5s ease;

    @include sfx-media-down(sm) {
      display: none;
    }
  }

  .help-block {
    left: 100%;
    top: 0;
    width: 570px;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */
    color: #B55B5B;
    display: block;
    position: absolute;
    padding: 0 0 0 30px;
  }

  &__input {
    position: relative;

    input {
      font-family: $font-jura;
      width: 100%;
      height: 60px;
      border: 2px solid #C4C4C4;
      border-radius: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */
      color: #5BB5AC;
      padding: 0 38px;
      transition: border .5s ease;

      @include sfx-media-down(sm) {
        height: 48px;
        font-size: 17px;
        padding: 0 20px;
      }

      &:focus {
        border: 2px solid #5BB5AC;
        transition: border .5s ease;

        + .form__tooltip {
          opacity: 1;
          transition: opacity 0.5s ease;
        }
      }
    }

    &.has-error input:focus {
      + .form__tooltip {
        opacity: 0;
      }
    }

    textarea {
      font-family: $font-jura;
      width: 100%;
      height: 143px;
      border: 2px solid #C4C4C4;
      border-radius: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */
      color: #5BB5AC;
      padding: 10px 38px;
      transition: border .5s ease;

      @include sfx-media-down(sm) {
        padding: 7px 20px;
        font-size: 17px;
        line-height: 150%;
        height: 143px;
      }

      &:focus {
        border: 2px solid #5BB5AC;
        transition: border .5s ease;
      }
    }
  }


  &__send {
    padding: 10px 0 0 0;
    text-align: center;

    @include sfx-media-down(sm) {
      padding-top: 0;
    }
  }

  &__btn {
    width: 315px;
    height: 66px;
    background: #5BB5AC;
    border: 2px solid #5BB5AC;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $font-jura;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #ffffff;
    transition: color .5s ease, background-color .5s ease, border .5s ease;
    margin: 0 auto;

    @include sfx-media-down(sm) {
      width: 201px;
      height: 48px;
      font-size: 17px;
      line-height: 20px;
    }

    &:hover {
      background: #5BB5AC;
      border: 2px solid #067065;
      color: #ffffff;
      transition: color .5s ease, background-color .5s ease, border .5s ease;
    }
  }

  &__note {
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
    /* or 22px */
    color: #656464;
    padding: 30px 0 0 0;
    display: none;

    @include sfx-media-down(sm) {
      display: block;
    }

    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 150%;
      /* or 22px */
      color: #656464;
      padding: 0 0 20px 0;
      margin: 0;
    }
  }


  &__wrap {
    position: relative;

    &.is-send {
      .form__result {
        z-index: 2;
        opacity: 1;
        transition: opacity .3s ease;
      }

      .form__form {
        z-index: 1;
      }
    }
  }

  &__result {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    font-weight: 500;
    font-size: 23px;
    line-height: 150%;
    color: #656464;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s ease;
  }

  &__form {
    position: relative;
    z-index: 2;
  }
}


