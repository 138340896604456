.projects {
  padding: 60px 0 60px 0;

  @include sfx-media-down(sm) {
    padding-top: 40px;
  }

  &__title {
    font-weight: 600;
    font-size: 50px;
    line-height: 150%;
    /* or 75px */
    color: #302D2D;
    padding: 0 0 40px 0;

    @include sfx-media-down(sm) {
      font-size: 28px;
      line-height: 150%;

      img {
        width: 28px;
      }
    }
  }


  &__list {
    overflow: hidden;
  }

  &__slider {
    width: 598px;
    margin: 0 auto;

    @include sfx-media-down(sm) {
      width: 100%;
    }
  }

  .slick-list {
    overflow: visible;
  }

  &__item {
    padding: 0 15px;

    &-inner {
      &:hover {
        .projects__item-link {
          transform: translateY(0);
          transition: transform 0.7s ease;
        }
      }
    }

    &-image {
      height: 272px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      position: relative;
      overflow: hidden;
      @include sfx-background-cover();

      @include sfx-media-down(sm) {
        height: 530px;
      }
    }

    &-link {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.23);
      position: absolute;
      z-index: 2;
      transform: translateY(272px);
      transition: transform 0.7s ease;

      @include sfx-media-down(sm) {
        transform: translateY(0);
      }
    }

    &-btn {
      width: 330px;
      height: 66px;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: $font-jura;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #302D2D;
      transition: color .5s ease, background-color .5s ease, border .5s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      @include sfx-media-down(sm) {
        width: 225px;
        height: 48px;
        font-size: 17px;
      }

      &:hover {
        background: #5BB5AC;
        border: 2px solid #5BB5AC;
        color: #ffffff;
        transition: color .5s ease, background-color .5s ease, border .5s ease;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 22px;
      line-height: 150%;
      /* or 33px */
      color: #302D2D;
      padding: 25px 0 10px 0;

      @include sfx-media-down(sm) {
        font-size: 21px;
        line-height: 150%;
      }
    }

    &-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */
      color: #656464;

      @include sfx-media-down(sm) {
        font-size: 17px;
        line-height: 150%;
      }
    }
  }


  &-list {
    &__nav {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 16px;
      z-index: 5;
      margin: 30px 0 0 0;
    }

    &__nav-right, &__nav-left {
      width: 68px;
      height: 16px;
      position: absolute;
      top: 0;
      cursor: pointer;
      transition: width .5s ease;
      overflow: hidden;

      &:hover {
        width: 168px;
        transition: width .5s ease;
      }

      img {
        width: 168px;
        height: 16px;
        position: absolute;
        top: 0;
        max-width: 168px;
      }
    }

    &__nav-right {
      left: 50%;

      img {
        right: 0;
      }
    }

    &__nav-left {
      margin: 0 15px 0 0;
      right: 50%;

      img {
        left: 0;
      }
    }
  }

}

