.header {
  padding: 0 0 0 0;
  height: 73px;
  background-color: transparent;
  position: relative;
  z-index: 1;

  @include sfx-media-down(410px) {
    .col {
      padding-left: 0;
    }
  }

  &__logo {
    img {
      height: 73px;

      @include sfx-media-down(410px) {
        height: 65px;
      }
    }

    .logo-full {
      display: block;

      @include sfx-media-down(sm) {
        display: none;
      }
    }

    .logo-mobile {
      display: none;

      @include sfx-media-down(sm) {
        display: block;
      }
    }
  }

  &__contacts {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;

    &-item {
      padding: 0 35px 0 0;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: right;
      color: #FFFFFF;

      @include sfx-media-down(sm) {
        display: none;
      }

      a {
        font-family: $font-jura;
        font-weight: 700;
        color: #FFFFFF;
        margin: 0 0 0 5px;
      }
    }

    &-item-mobile {
      display: none;
      padding: 0 20px 0 0;

      a {
        font-family: $font-jura;
        font-weight: 700;
        color: #FFFFFF;
        margin: 0 0 0 5px;
        font-size: 15px;
        line-height: 18px;

        @include sfx-media-down(sm) {
          margin: 0;
        }

        @include sfx-media-down(410px) {
          font-size: 12px;
        }
      }

      @include sfx-media-down(sm) {
        display: block;
      }

      @include sfx-media-down(410px) {
        padding: 0 15px 0 0;
      }
    }
  }

  &__lang {
    width: 95px;
    position: relative;

    @include sfx-media-down(410px) {
      //display: none;
    }

    &.active {
      .header__lang-current {
        background-color: #5BB5AC;
        transition: background-color .3s ease;
        border-radius: 10px 10px 0px 0px;

        img {
          filter: grayscale(0);
          opacity: 1;
          transition: opacity 0.5s ease, filter .5s ease;
        }
      }
    }

    &-current {
      padding: 10px 10px 10px 55px;
      position: relative;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */
      color: #FFFFFF;
      display: flex;
      align-items: center;
      border-radius: 10px 10px 10px 10px;
      transition: background-color .3s ease;
      height: 44px;
      cursor: pointer;

      &:hover {
        background-color: #5BB5AC;
        transition: background-color .3s ease;

        img {
          filter: grayscale(0);
          opacity: 1;
          transition: opacity 0.5s ease, filter .5s ease;
        }
      }

      img {
        width: 33px;
        height: 24px;
        position: absolute;
        top: 10px;
        left: 10px;
        filter: grayscale(100%);
        opacity: 0.7;
        transition: opacity 0.5s ease, filter .5s ease;
      }
    }

    &-list {
      display: none;
      position: absolute;
      left: 0;
      top: 44px;
      width: 100%;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
    }

    &-item {
      padding: 10px 10px 10px 55px;
      position: relative;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */
      color: #FFFFFF;
      display: flex;
      align-items: center;
      border-radius: 0;
      transition: background-color .3s ease;
      height: 44px;
      cursor: pointer;
      background-color: #C4C4C4;

      &:hover {
        background-color: #5BB5AC;
        transition: background-color .3s ease;
      }

      img {
        width: 33px;
        height: 24px;
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
  }

}
