.technology {

  &__slider {
    height: 709px;
    position: relative;
    overflow: hidden;
  }

  &__item {
    @include sfx-background-cover();
    background-image: url(images/technology.png);
    position: absolute;
    width: 100%;
    height: 709px;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(100%);

    &.current {
      z-index: 10;
      transform: translateY(0);
      transition: transform .5s ease;
    }

    &.prev {
      z-index: 8;
      transform: translateY(0);
    }

    &-inner {
      height: 709px;
      padding: 80px 0 0 0;

      @include sfx-media-down(sm) {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }

    &-inner-2 {

    }

    &-title {
      font-weight: 600;
      font-size: 80px;
      line-height: 98px;
      /* identical to box height */
      color: #302D2D;
      padding: 0 0 50px 0;

      @include sfx-media-down(sm) {
        font-size: 30px;
        line-height: 37px;
      }
    }

    &-title-2 {
      font-weight: 600;
      font-size: 60px;
      line-height: 73px;
      color: #302D2D;
      padding: 0 0 35px 0;

      @include sfx-media-down(sm) {
        font-size: 23px;
        line-height: 28px;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 40px;
      line-height: 150%;
      /* or 60px */
      color: #302D2D;

      @include sfx-media-down(sm) {
        font-size: 21px;
        line-height: 150%;
      }
    }
  }


  &__nav {
    position: relative;
    width: 100%;
    z-index: 11;
  }

  &__nav-up, &__nav-down {
    width: 16px;
    height: 68px;
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: height .5s ease;
    overflow: hidden;

    &:hover {
      height: 168px;
      transition: height .5s ease;
    }

    img {
      height: 168px;
      width: 16px;
      position: absolute;
      right: 0;
      max-height: 168px;
    }
  }

  &__nav-up {
    bottom: 200px;

    img {
      top: 0;
    }
  }

  &__nav-down {
    top: -185px;

    img {
      bottom: 0;
    }
  }

}
