.footer {
  background: #F1F1F1;
  padding: 13px 0 10px 0;

  @include sfx-media-down(sm) {
    padding: 18px 0 15px 0;
  }

  &__empty {
    height: 50px;
  }

  &__row {
    display: flex;
    align-items: center;

    @include sfx-media-down(sm) {
      flex-wrap: wrap;
    }
  }

  &__col-1 {
    @include sfx-flex-col(6);
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    color: #302D2D;

    @include sfx-media-down(sm) {
      @include sfx-flex-col(12);
      font-size: 15px;
      line-height: 150%;
      text-align: center;
      padding-bottom: 10px;
    }
  }

  &__col-2 {
    @include sfx-flex-col(6);
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    color: #656464;
    text-align: right;

    @include sfx-media-down(sm) {
      @include sfx-flex-col(12);
      font-size: 14px;
      line-height: 150%;
      text-align: center;
    }

    img {
      width: 18px;
      height: 14px;
    }
  }
}

