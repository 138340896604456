.command {
  padding: 60px 0;

  &__row {
    display: flex;
    margin: 0 -15px;
    align-items: center;

    @include sfx-media-down(sm) {
      flex-wrap: wrap;
    }
  }

  &__info {
    @include sfx-flex-col(6);
    padding: 0 15px;

    @include sfx-media-down(sm) {
      @include sfx-flex-col(12);
    }
  }

  &__image {
    @include sfx-flex-col(6);
    padding: 0 15px;

    @include sfx-media-down(sm) {
      @include sfx-flex-col(12);
      padding-top: 30px;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 100%;

      @include sfx-media-down(sm) {
        max-width: 350px;
        width: 100%;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    /* identical to box height */
    color: #302D2D;
    padding: 0 0 20px 0;

    @include sfx-media-down(sm) {
      font-size: 28px;
      line-height: 34px;
      padding: 0 0 30px 0;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    color: #656464;

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      /* or 27px */
      color: #656464;
      padding: 0 0 25px 0;
      margin: 0;

      @include sfx-media-down(sm) {
        font-size: 18px;
        line-height: 150%;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
