.modal {
  .modal-dialog {
    max-width: calc(100% + 17px);
    width: calc(100% + 17px);
    margin: 0;

    @include sfx-media-down(sm) {
      max-width: calc(100% + 0px);
      width: calc(100% + 0px);
    }
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: #FFFFFF;
  }

  .modal-header {
    border: 0;
    padding-top: 35px;
    padding-bottom: 0px;
    flex-wrap: wrap;
    min-height: 85px;
    position: relative;

    @include sfx-media-down(sm) {
      min-height: 70px;
    }
  }

  .close {
    font-size: 0;
    font-weight: 400;
    line-height: 1;
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    background: url(images/exit-hover.png) no-repeat center;
    background-size: 50px auto;
    padding: 0;
    filter: grayscale(100%);
    opacity: 0.6;
    transition: opacity .5s ease, filter .5s ease;

    @include sfx-media-down(sm) {
      width: 42px;
      height: 42px;
      background-size: 42px auto;
    }

    &:hover {
      filter: grayscale(0%);
      opacity: 1;
      transition: opacity .5s ease, filter .5s ease;
    }
  }

  .modal-body {
    padding: 0 45px 50px 45px;

    @include sfx-media-down(sm) {
      padding: 0 15px 30px 15px;
    }
  }

}


