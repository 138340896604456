
.project-detail {
  max-width: 970px;
  margin: 0 auto;
  padding: 20px 0 0 0;

  &__title {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    /* identical to box height */
    color: #302D2D;
    padding: 0 0 40px 0;

    @include sfx-media-down(sm) {
      font-weight: 600;
      font-size: 21px;
      line-height: 150%;
    }
  }

  &__link {
    padding: 20px 0 20px 0;

    a {
      font-weight: normal;
      font-size: 17px;
      line-height: 150%;
      /* or 25px */
      text-decoration-line: underline;
      color: #5BB5AC;
    }
  }

  &__text {
    font-weight: normal;
    font-size: 17px;
    line-height: 150%;
    /* or 27px */
    color: #656464;

    p {
      font-weight: normal;
      font-size: 17px;
      line-height: 150%;
      /* or 27px */
      color: #656464;
      padding: 0 0 20px 0;
      margin: 0;
    }

    b {
      font-weight: 700;
    }

    p + ul {
      margin-top: -10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;

      li {
        list-style: none;
        font-weight: normal;
        font-size: 17px;
        line-height: 150%;
        /* or 25px */
        color: #656464;
        padding: 0 0 0 40px;
        position: relative;
        margin: 0 0 7px 0;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          display: block;
          width: 20px;
          height: 1px;
          background: #656464;
          border-radius: 5px;
          position: absolute;
          top: 13px;
          left: 15px;
        }
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: 0 auto 30px 0;
    }

  }


  &__review {
    margin-top: 40px;

    &-row {
      display: flex;
      margin: 0 -15px;

      @include sfx-media-down(sm) {
        flex-wrap: wrap;
      }
    }

    &-content {
      @include sfx-flex-col-custom(60%);
      padding: 0 15px;

      @include sfx-media-down(sm) {
        @include sfx-flex-col-custom(100%);
        padding: 0;
      }
    }

    &-image {
      @include sfx-flex-col-custom(40%);
      padding: 0 15px;
      height: 100%;

      @include sfx-media-down(sm) {
        @include sfx-flex-col-custom(100%);
        margin: 34px 0 0 0;
        text-align: center;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        margin: 0;

        @include sfx-media-down(sm) {
          max-width: 235px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }

    &-inner {
      background: #F1F1F1;
      padding: 25px 15px 15px 15px;
      height: 100%;
    }

    &-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 150%;
      /* or 42px */
      color: #302D2D;
      padding: 0 0 20px 0;

      @include sfx-media-down(sm) {
        font-size: 21px;
        line-height: 150%;
      }
    }

    &-text {
      font-weight: normal;
      font-size: 17px;
      line-height: 150%;
      /* or 25px */
      color: #656464;

      p {
        font-weight: normal;
        font-size: 17px;
        line-height: 150%;
        /* or 25px */
        color: #656464;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
      }
    }

    &-note {
      font-weight: 500;
      font-size: 17px;
      line-height: 150%;
      /* or 25px */
      color: #656464;
      padding: 20px 0 0 15px;

      @include sfx-media-down(sm) {
        padding: 25px 0 0 0;
      }
    }
  }


  &__gallery {
    position: relative;
    overflow: hidden;
    margin: 0 0 0 0;

    @include sfx-media-down(sm) {
      margin: 0 -15px;
    }
  }

  &__slider {
    width: calc(100% - 120px);

    @include sfx-media-down(sm) {
      width: calc(100% - 50px);
    }

    &-item {
      padding: 0 15px;
    }

    &-image {
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .slick-list {
    overflow: visible;
    margin: 0 -15px;
  }



  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 16px;
    z-index: 5;
    margin: 30px 0 0 0;
  }

  &__nav-right, &__nav-left {
    width: 68px;
    height: 16px;
    position: absolute;
    top: 0;
    cursor: pointer;
    transition: width .5s ease;
    overflow: hidden;

    &:hover {
      width: 168px;
      transition: width .5s ease;
    }

    img {
      width: 168px;
      height: 16px;
      position: absolute;
      top: 0;
      max-width: 168px;
    }
  }

  &__nav-right {
    left: 50%;

    img {
      right: 0;
    }
  }

  &__nav-left {
    margin: 0 15px 0 0;
    right: 50%;

    img {
      left: 0;
    }
  }

}



