
$font-jura: 'Jura', sans-serif;
$font-mont: 'Montserrat', sans-serif;

$sfx-root-font-size: 10px;
$sfx-font-size: 16px;
$sfx-text-color: #474747;
$sfx-line-height: 1.6;


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$container-max-widths: (
        sm: 540px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

@import "bootstrap/scss/bootstrap";


$sfx-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$sfx-content-size: (
        sm: 540px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);


:root {
  @each $point, $value in $sfx-breakpoints {
    --sfx-breakpoint-#{$point}: #{$value};
  }
}


@import "inc/mixins";
@import "inc/reboot";


.container {
  min-width: 320px;
}


//@import "../../node_modules/bourbon/core/bourbon";

@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";

@import "inc/font";

@import "vendor/fm.revealator.jquery";


@import "inc/base";
@import "inc/header";
@import "inc/footer";
@import "inc/modal";
@import "inc/index";




