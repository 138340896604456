
// _reboot.scss

*, *::before, *::after {
  margin: 0; padding: 0; border: 0;
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  min-height: 100%;
}

a {
  background: transparent;

  &:hover, &:focus, &:active {
    outline: none;
  }
}

article, aside, details,
figcaption, figure, footer,
header, main, nav, section,
summary {
  display: block;
}

audio, canvas,
progress, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

svg:not(:root) {
  overflow: hidden;
}

img {
  height: auto;
  max-width: 100%;
  user-select: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

label {
  display: inline-block;
  cursor: pointer;

  &:not([for]) {
    cursor: default;
  }
}

button, input,
select, textarea {
  font: inherit;
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0; padding: 0;
  }
}

textarea {
  resize: none;
}

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

input[type="search"] {

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

button, [type="button"],
[type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: transparent;
  cursor: pointer;
}
