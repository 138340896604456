html {
  font: #{$sfx-root-font-size}/#{$sfx-line-height} $font-mont;
  font-weight: 400;
  min-height: 100%;
  height: auto;

  @include sfx-media-down(md) {

  }
}

body {
  color: $sfx-text-color;
  font-size: $sfx-font-size;
  font-family: $font-mont;
  font-weight: 400;
  /* fix для растягивания по высоте */
  //display: flex;
  //flex-direction: column;
  height: auto;
  background: #ffffff;
  position: relative;
}

a {
  &:hover {
    text-decoration: none;
  }
}


.container {
  min-width: 320px;
}


::placeholder {
  opacity: 1;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #302D2D;

  @include sfx-media-down(sm) {
    font-size: 17px;
    line-height: 150%;
  }
}
:-ms-input-placeholder {
  opacity: 1;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #302D2D;

  @include sfx-media-down(sm) {
    font-size: 17px;
    line-height: 150%;
  }
}
::-ms-input-placeholder {
  opacity: 1;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #302D2D;

  @include sfx-media-down(sm) {
    font-size: 17px;
    line-height: 150%;
  }
}


h2 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.75px;
  position: relative;
  padding: 0 0 40px 0;
  margin: 0 0 35px 0;

  &:after {
    content: '';
    display: block;
    width: 45px;
    height: 3px;
    background-color: #fce300;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}


