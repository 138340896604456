.contacts {
  padding: 20px 0 50px 0;

  &__title {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    /* identical to box height */
    color: #302D2D;
    padding: 0 0 40px 0;

    @include sfx-media-down(sm) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__row {
    display: flex;
    margin: 0 -15px;

    @include sfx-media-down(sm) {
      flex-wrap: wrap;
    }
  }

  &__left {
    @include sfx-flex-col-custom(62%);
    padding: 0 15px;

    @include sfx-media-down(sm) {
      @include sfx-flex-col-custom(100%);
    }

    &-row {
      display: flex;
      margin: 0 -15px;
      flex-wrap: wrap;
    }
  }

  &__right {
    @include sfx-flex-col-custom(38%);
    padding: 0 15px;

    @include sfx-media-down(sm) {
      @include sfx-flex-col-custom(100%);
    }
  }


  &__item {
    margin: 0 0 30px 0;
    padding: 0 15px;

    &.item-1 {
      @include sfx-flex-col-custom(100%);
    }

    &.item-2 {
      //@include sfx-flex-col-custom(50%);
    }

    &.item-3 {
      //@include sfx-flex-col-custom(50%);
    }

    &.item-7 {
      @include sfx-flex-col-custom(100%);

      @include sfx-media-down(sm) {
        padding: 0;
      }
    }

    @include sfx-media-down(sm) {
      @include sfx-flex-col-custom(100%);
    }

    &-inner {
      background: #F1F1F1;
      border-radius: 30px;
      padding: 20px 30px;
      transition: color .5s ease, background-color .5s ease;

      &:hover {
        background: #5BB5AC;
        color: #ffffff;
        transition: color .5s ease, background-color .5s ease;

        .contacts__item-title, .contacts__item-text {
          color: #ffffff;
          transition: color .5s ease;

          a {
            color: #ffffff;
            transition: color .5s ease;
          }
        }
      }
    }

    &-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 150%;
      /* or 27px */
      color: #3D3C3C;
      padding: 0 0 10px 0;
      transition: color .5s ease;
    }

    &-text {
      font-family: $font-jura;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      /* or 27px */
      color: #656464;
      transition: color .5s ease;

      &.requisit {
        line-height: 36px;
      }

      a {
        color: #656464;
        transition: color .5s ease;
      }
    }
  }

}
