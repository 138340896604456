
// _mixins.scss


// Media queries

@function get-breakpoint-value($query, $data) {
  $value: null;

  @if type-of($query) == "number" {
    $value: $query
  }

  @if type-of($query) == "string" {
    $value: map-get($data, $query);
  }

  @return $value;
}

@mixin sfx-media-up($query, $data: $sfx-breakpoints) {
  $value: get-breakpoint-value($query, $data);

  @media (min-width: $value) {
    @content;
  }
}

@mixin sfx-media-down($query, $data: $sfx-breakpoints) {
  $value: get-breakpoint-value($query, $data);

  @media (max-width: $value - 0.02px) {
    @content;
  }
}

@mixin sfx-media-between($min-query, $max-query, $data: $sfx-breakpoints) {
  $min-value: get-breakpoint-value($min-query, $data);
  $max-value: get-breakpoint-value($max-query, $data);

  @media (max-width: $max-value - 0.02px) and (min-width: $min-value) {
    @content;
  }
}



// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}


@mixin sfx-background-cover($pos:center) {
  background-size: cover;
  background-position: $pos;
  background-repeat: no-repeat;
}


@mixin sfx-absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin sfx-flex-col-auto() {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
@mixin sfx-flex-col-100() {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}


@mixin sfx-flex-col-custom($size: 0) {
  flex: 0 0 $size;
  max-width: $size;
}

@mixin sfx-flex-col($col: 1) {
  @include sfx-flex-col-custom(calc(100% * $col / 12));
}
